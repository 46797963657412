import * as React from 'react';
import {QueryClient} from '@tanstack/react-query'

import {Button, Box, Card, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";

import Loading from "../../components/Loading";
import {Link as RouterLink} from "react-router-dom";

import TitleCard from "../../components/TitleCard";
import ProductListComponent from "./Cards/ProductListComponent";
import Chip from "@mui/material/Chip";

import productService from '../../services/ProductService';
import {AuthContextProps, useAuth} from "react-oidc-context";

export default function ProductsList(): JSX.Element {
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const auth: AuthContextProps = useAuth();
    const queryClient: QueryClient = new QueryClient();
    //const [intervalMs, setIntervalMs] = React.useState(2000)

    const {
        status,
        error,
        data,
        isFetching,
        isPreviousData
    } = productService.getAllProduct(auth, page);

    React.useEffect(() => {
        if (!isPreviousData && data?.hasMore) {
            productService.productsList(auth, queryClient, page);
        }
    }, [data, isPreviousData, page])

    // new
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    return (
        <Box sx={{my: 4}}>
            {status === 'loading' ? (
                <Loading/>
            ) : status === 'error' ? (
                <div>Error: {'error.message'}</div>
            ) : (
                <Card sx={{minWidth: 275}}>
                    <CardContent>
                        <TitleCard>Liste des produits</TitleCard>
                        <ProductListComponent data={data.content}/>
                    </CardContent>
                    <Stack sx={{m: 1}} direction="row" justifyContent="space-between" spacing={0}>
                        <Button component={RouterLink} to="/create/step/1">
                            Ajouter un produit
                        </Button>
                        <Stack direction="row" justifyContent="space-evenly" spacing={0}>
                            <Button
                                onClick={() => setPage((old) => Math.max(old - 1, 0))}
                                disabled={page === 0}
                            >
                                Previous Page
                            </Button>
                            <Chip label={`${page + 1}/${data?.totalPages}`} variant="outlined"/>
                            <Button
                                onClick={() => {
                                    setPage((old) => (!data?.last ? old + 1 : old))
                                }}
                                disabled={isPreviousData || data?.last}
                            >
                                Next Page
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
            )}
        </Box>
    );
}