import React from "react"
import {Route, Routes, Link} from "react-router-dom"
import {Dashboard, Products, ProductInfo, Trash, Store, Configuration} from "./pages";
import {SaveProductPage} from "./pages/ProductSave/SaveProductPage";

const MainRoutes = () => (
    <Routes>
        <Route index element={<Dashboard/>}/>
        <Route path="/create/step/:step" element={<SaveProductPage/>}/>
        <Route path="/product/:updateId/step/:step" element={<SaveProductPage/>}/>
        <Route path="/products" element={<Products/>}/>
        <Route path="/product/:id" element={<ProductInfo />}/>
        <Route path="/store" element={<Store/>}/>
        <Route path="/trash" element={<Trash />}/>
        <Route path="/configuration" element={<Configuration />}/>
        <Route path="*" element={<NoMatch />} />
    </Routes>
)

export default MainRoutes

function NoMatch() {
    return (
        <div>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}