import React from "react";
import {FormControl, FormLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import {FormInputProps} from "./FormInputProps";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {useQuery} from "@tanstack/react-query";
import {getSupportedClientAuthMethods} from "../../services/ConfigurationProductService";
import Loading from "../Loading";

export const FormInputDropdownClientAuthMethod: React.FC<FormInputProps> = ({
                                                                                name,
                                                                                control,
                                                                                label,
                                                                            }) => {
    const auth: AuthContextProps = useAuth();

    const clientAuthMethodsQuery = useQuery(
        ['supportedClientAuthMethods'],
        () => getSupportedClientAuthMethods(auth.user?.access_token)
    )

    if (clientAuthMethodsQuery.isLoading) {
        return <Loading/>
    }

    return (
        <FormControl size={"small"}>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
                render={({field: {onChange, value}}) => (
                    <Select onChange={onChange} value={value}>
                        {
                            clientAuthMethodsQuery.data.map((method: { [x: string]: any; }) => {
                                let key :string = Object.keys(method)[0]
                                return (
                                    <MenuItem key={key} value={key}>
                                        {method[key]}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};