import * as React from 'react';
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";

export const DataBaseInfoComponent: React.FC<{
    name: any, demoDb:boolean|undefined, hostname: any, port: any, username: any, password: any
}> = (
    props: { name: any, demoDb:boolean|undefined, hostname: any, port: any, username: any, password: any }
) => {
    return (
        <TableContainer sx={{ mb: 2 }}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow key='tbd1'>
                        <TableCell component="th" scope="row">demoDb</TableCell>
                        <TableCell component="th" scope="row">{props.demoDb ? 'oui' : 'non'}</TableCell>
                    </TableRow>
                    <TableRow key='tbd2'>
                        <TableCell component="th" scope="row">name</TableCell>
                        <TableCell component="th" scope="row">{props.name}</TableCell>
                    </TableRow>

                    {props.demoDb==true &&
                        <>
                            <TableRow key='tbd3'>
                                <TableCell component="th" scope="row">hostname</TableCell>
                                <TableCell component="th" scope="row">{props.hostname}</TableCell>
                            </TableRow>
                            <TableRow key='tbd4'>
                                <TableCell component="th" scope="row">port</TableCell>
                                <TableCell component="th" scope="row">{props.port}</TableCell>
                            </TableRow>
                            <TableRow key='tbd5'>
                                <TableCell component="th" scope="row">username</TableCell>
                                <TableCell component="th" scope="row">{props.username}</TableCell>
                            </TableRow>
                            <TableRow key='tbd6'>
                                <TableCell component="th" scope="row">password</TableCell>
                                <TableCell component="th" scope="row">{props.password}</TableCell>
                            </TableRow>
                        </>}

                </TableBody>
            </Table>
        </TableContainer>
    );
}