import * as React from 'react'
import {ReactEventHandler} from 'react'
import Box from '@mui/material/Box';

import {QueryClient, useQueryClient} from "@tanstack/react-query";
import Loading from "../../../../components/Loading";
import CardContent from "@mui/material/CardContent";
import {Badge, Card} from "@mui/material";
import productService from "../../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {ProductInfoComponent} from "../components/ProductInfoComponent";
import {ComplementInfoComponent} from "../components/ComplementInfoComponent";
import {DataBaseInfoComponent} from "../components/DataBaseInfoComponent";
import {KeycloakInfoComponent} from "../components/KeycloakInfoComponent";
import Grid from "@mui/material/Grid";
import TitleCard from "../../../../components/TitleCard";


export default function DetailContainer(props: any) {
    const queryClient: QueryClient = useQueryClient();
    const auth: AuthContextProps = useAuth();

    const {isLoading, error, data, isFetching} = productService.getProductById(auth, props.id);

    function onUpdateChange(e: ReactEventHandler) {
        queryClient.invalidateQueries({queryKey: ['productDetail']})
    }
    if (isLoading && data) {
        return <Loading/>
    } else {
        console.log(data)
        return (
            <Badge badgeContent={data?.numeroVersion} color="primary"
                   overlap="rectangular"
                   sx={{"& .MuiBadge-badge": {fontSize: 18, height: 40, minWidth: 40}}}>
                <Card sx={{minHeight: 0}}>
                    <CardContent>
                        <TitleCard> Détail du produit</TitleCard>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12} xs={12}>
                                <ProductInfoComponent id={props.id} name={data?.name} status={data?.status}
                                                      templateVersion={data?.templateVersion}
                                                      step={data?.step} resources={data?.assemblyResources}/>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>

                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{typography: 'subtitle2'}}>Compléments</Box>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <ComplementInfoComponent serverAlias={data?.serverAlias}
                                                                 defaultLanguage={data?.defaultLanguage}
                                                                 deploy={data?.deploy}
                                                                 defaultCurrency={data?.defaultCurrency}/>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{typography: 'subtitle2'}}>Base de données</Box>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <DataBaseInfoComponent demoDb={data?.assemblyDatabase?.demoDb}
                                                               name={data?.assemblyDatabase?.name}
                                                               hostname={data?.assemblyDatabase?.hostname}
                                                               password={data?.assemblyDatabase?.password}
                                                               port={data?.assemblyDatabase?.port}
                                                               username={data?.assemblyDatabase?.username}/>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{typography: 'subtitle2'}}>Keycloak</Box>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <KeycloakInfoComponent keycloak={data?.assemblyKeycloak}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Badge>
        )
    }
}