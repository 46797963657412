import React from "react";

import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormInputProps} from "./FormInputProps";

export const FormInputText = (props: FormInputProps) => {
    let typeTx: string = 'text'
    if('password' === props.type){
        typeTx = 'password';
    }
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          helperText={error ? error.message : null}
          type={typeTx}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={props.label}
          disabled={props.disabled}
          variant="outlined"
        />
      )}
    />
  );
};