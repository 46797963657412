import * as React from 'react';
import {PackageNpm} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import Grid from "@mui/material/Grid";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import catalogueService from "../../../services/CatalogueService";

export const PackageNpmListComponent: React.FC<{
    packageNpmDtos: PackageNpm[],
    auth: AuthContextProps,
    updateMode: boolean,
    updatePackageNpmDtos: any, disabled?: boolean
}> = (
    props: { packageNpmDtos: PackageNpm[], auth: AuthContextProps, updateMode: boolean, updatePackageNpmDtos: any, disabled?: boolean }
) => {

    const onDeletePackageNpm = (idPackageNpm: number | undefined | null) => {
        if (idPackageNpm) {
            catalogueService.deletePackageNpm(props.auth, idPackageNpm).then(value => {
                props.updatePackageNpmDtos(props.packageNpmDtos.filter(value => value.id != idPackageNpm));
            })
        }
    }

    return (props.packageNpmDtos && props.packageNpmDtos.length > 0) ? (
        <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
            <Grid item xs={12} md={12}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="right">Version</TableCell>
                            {props.updateMode && <TableCell align="right">Supprimer</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.packageNpmDtos.map((row: PackageNpm) => (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.version}</TableCell>
                                {props.updateMode &&  <TableCell align="right" >
                                    <IconButton aria-label="delete" color="warning" disabled={props.disabled} onClick={event => {
                                        onDeletePackageNpm(row.id);
                                    }}>
                                        <DeleteOutlineIcon/>
                                    </IconButton>
                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Grid>
        </Grid>
    ) : (<></>);
}