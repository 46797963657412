import * as React from "react"
import { useRef } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import {AuthContextProps, useAuth} from "react-oidc-context"

// UI
import {Button, Card, CardContent, Chip, Switch,
    CardActions, Stack, Alert, Box, TextField,
    FormGroup, FormControlLabel} from "@mui/material";
import TitleCard from "../../components/TitleCard";
import Loading from "../../components/Loading";
import {useConfirm} from "material-ui-confirm";

// SERVICES
import {getSupportedCurrencies, postSupportedCurrency, deleteSupportedCurrency} from "../../services/ConfigurationProductService";
import {AxiosError} from "axios";

export default function CurrenciesComponent(props:any) {
    const auth: AuthContextProps = useAuth();
    const input = useRef<HTMLInputElement>(null)
    const confirm = useConfirm();

    const [checkedDelete, setCheckedDelete] = React.useState(false);
    const [checkedAdd, setCheckedAdd] = React.useState(false);

    const currenciesQuery = useQuery(
        ['supportedCurrencies'],
        () => getSupportedCurrencies(auth.user?.access_token)
    )

    const createCurrencyMutation = useMutation({
        mutationFn: (currency: string) => postSupportedCurrency(currency, auth.user?.access_token),
        onSuccess: () => {
            currenciesQuery.refetch()
        },
    })

    const deleteCurrencyMutation = useMutation({
        mutationFn: (currency: string) => deleteSupportedCurrency(currency, auth.user?.access_token),
        onSuccess: () => {
            currenciesQuery.refetch()
        },
    })

    if (currenciesQuery.isLoading) {
        return <Loading />
    }

    const handleCheckedAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAdd(event.target.checked)
        if (event.target.checked) {
            createCurrencyMutation.reset()
        }
    };

    const handleCheckedDelete = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedDelete(event.target.checked)
        deleteCurrencyMutation.reset()
    };

    const handleDelete = (chipToDelete: string) => () => {
        console.log(chipToDelete)

        confirm({
            title: "Êtes-vous sûr de vouloir supprimer la devise.",
            description: `La devise : ${chipToDelete}`,
            cancellationText: "Annuler"
        })
            .then(() => {
                deleteCurrencyMutation.mutate(chipToDelete)
            }).catch(() => {
        });
    };

    const messageError = (errorCreating: any) => {
        if (errorCreating instanceof AxiosError) {
            return (
                <Alert severity="error">Erreur de création : {errorCreating.response?.data}</Alert>
            )
        } else {
            return (
                <Alert severity="error">Erreur de création : {errorCreating.message}</Alert>
            )
        }
    };

    const messageErrorDelete = (errorDeleting: any) => {
        if (errorDeleting instanceof AxiosError) {
            return (
                <Alert severity="error">Erreur de suppression : {errorDeleting.response?.data}</Alert>
            )
        } else {
            return (
                <Alert severity="error">Erreur de suppression : {errorDeleting.message}</Alert>
            )
        }
    };

    return (
        <>
            <Card sx={{minHeight: 0, mb: 2}}>
                <CardContent>
                    <TitleCard>Devise</TitleCard>
                    <Box>
                        {!currenciesQuery.isLoading && currenciesQuery.data && currenciesQuery.data.length > 0 && (
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                {currenciesQuery.data.map((dataCurrencie: any) => (
                                        <Chip
                                            key={dataCurrencie}
                                            label={dataCurrencie}
                                            onDelete={checkedDelete ? handleDelete(dataCurrencie) : undefined}
                                        />
                                ))}
                            </Stack>
                        )}
                    </Box>
                    <Box mt={2}>
                        {deleteCurrencyMutation.isError && messageErrorDelete(deleteCurrencyMutation.error)}
                    </Box>
                </CardContent>
                <CardActions>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={checkedAdd} onChange={handleCheckedAdd}/>}
                            label="Ajouter une devise"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={checkedDelete} onChange={handleCheckedDelete}/>}
                            label="Supprimer une devise"
                        />
                    </FormGroup>
                </CardActions>
                {checkedAdd &&
                    <>
                        <Box m={2}>
                            Il existe environ 300 types de monnaies différentes utilisées dans les échanges commerciaux
                            à travers le monde. Pour faciliter ces échanges, il est indispensable d’utiliser ISO 4217.<br/>
                            <ul>
                                <li><a href="https://www.iso.org/fr/iso-4217-currency-codes.html"
                                       target="_blank">https://www.iso.org/fr/iso-4217-currency-codes.html</a></li>
                                <li><a href="https://fr.wikipedia.org/wiki/ISO_4217"
                                       target="_blank">https://fr.wikipedia.org/wiki/ISO_4217</a></li>
                            </ul>

                        </Box>
                        <Box m={2}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={1}
                            >
                                <TextField label="Nouvelle devise" fullWidth size="small" inputRef={input}/>
                                <Button onClick={() => {
                                    if (input.current?.value) {
                                        createCurrencyMutation.mutate(input.current.value)
                                        input.current.value = ''
                                    }
                                }}>Ajouter</Button>
                            </Stack>
                        </Box>
                        <Box m={2}>
                            {createCurrencyMutation.isError && messageError(createCurrencyMutation.error)}
                        </Box>
                    </>
                }
            </Card>
        </>
    )
}