import * as React from "react"
import { useRef } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import {AuthContextProps, useAuth} from "react-oidc-context"

// UI
import {Button, Card, CardContent, Chip, Switch,
    CardActions, Stack, Alert, Box, TextField,
    FormGroup, FormControlLabel} from "@mui/material";
import TitleCard from "../../components/TitleCard";
import Loading from "../../components/Loading";
import {useConfirm} from "material-ui-confirm";

// SERVICES
import {getSupportedLanguages,postSupportedLanguage,deleteSupportedLanguage,} from "../../services/ConfigurationProductService";
import {AxiosError} from "axios";

export default function LanguagesComponent(props:any) {
    const auth: AuthContextProps = useAuth();
    const input = useRef<HTMLInputElement>(null)
    const confirm = useConfirm();

    const [checkedDelete, setCheckedDelete] = React.useState(false);
    const [checkedAdd, setCheckedAdd] = React.useState(false);

    const languagesQuery = useQuery(
        ['supportedLanguages'],
        () => getSupportedLanguages(auth.user?.access_token)
    )

    const createLanguageMutation = useMutation({
        mutationFn: (language: string) => postSupportedLanguage(language, auth.user?.access_token),
        onSuccess: () => {
            languagesQuery.refetch()
        },
    })

    const deleteLanguageMutation = useMutation({
        mutationFn: (language: string) => deleteSupportedLanguage(language, auth.user?.access_token),
        onSuccess: () => {
            languagesQuery.refetch()
        },
    })

    if (languagesQuery.isLoading) {
        return <Loading />
    }

    const handleCheckedAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAdd(event.target.checked)
        if (event.target.checked) {
            createLanguageMutation.reset()
        }
    };

    const handleCheckedDelete = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedDelete(event.target.checked)
        deleteLanguageMutation.reset()
    };

    const handleDelete = (chipToDelete: string) => () => {
        console.log(chipToDelete)

        confirm({
            title: "Êtes-vous sûr de vouloir supprimer la langue.",
            description: `La langue : ${chipToDelete}`,
            cancellationText: "Annuler"
        })
            .then(() => {
                deleteLanguageMutation.mutate(chipToDelete)
            }).catch(() => {
        });
    };

    const messageError = (errorCreating: any) => {
        if (errorCreating instanceof AxiosError) {
            return (
                <Alert severity="error">Erreur de création : {errorCreating.response?.data}</Alert>
            )
        } else {
            return (
                <Alert severity="error">Erreur de création : {errorCreating.message}</Alert>
            )
        }
    };

    const messageErrorDelete = (errorDeleting: any) => {
        if (errorDeleting instanceof AxiosError) {
            return (
                <Alert severity="error">Erreur de suppression : {errorDeleting.response?.data}</Alert>
            )
        } else {
            return (
                <Alert severity="error">Erreur de suppression : {errorDeleting.message}</Alert>
            )
        }
    };

    return (
        <>
            <Card sx={{minHeight: 0, mb: 2}}>
                <CardContent>
                    <TitleCard>Langue</TitleCard>
                    <Box>
                        {!languagesQuery.isLoading && languagesQuery.data && languagesQuery.data.length > 0 && (
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                {languagesQuery.data.map((dataLanguage: any) => (
                                    <Chip
                                        key={dataLanguage}
                                        label={dataLanguage}
                                        onDelete={checkedDelete ? handleDelete(dataLanguage) : undefined}
                                    />
                                ))}
                            </Stack>
                        )}
                    </Box>
                    <Box mt={2}>
                        {deleteLanguageMutation.isError && messageErrorDelete(deleteLanguageMutation.error)}
                    </Box>
                </CardContent>
                <CardActions>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={checkedAdd} onChange={handleCheckedAdd}/>}
                            label="Ajouter une langue"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={checkedDelete} onChange={handleCheckedDelete}/>}
                            label="Supprimer une langue"
                        />
                    </FormGroup>
                </CardActions>
                {checkedAdd &&
                    <>
                        <Box m={2}>
                            Sous forme d'étiquettes d’identification de langues (Language Tag) IETF.<br/>
                            Exemple : fr-FR
                            <ul>
                                <li><a href="https://en.wikipedia.org/wiki/IETF_language_tag"
                                       target="_blank">https://www.iso.org/fr/iso-3166-country-codes.html</a></li>
                            </ul>
                        </Box>
                        <Box m={2}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={1}
                            >
                                <TextField label="Nouvelle langue" fullWidth size="small" inputRef={input}/>
                                <Button onClick={() => {
                                    if (input.current?.value) {
                                        createLanguageMutation.mutate(input.current.value)
                                        input.current.value = ''
                                    }
                                }}>Ajouter</Button>
                            </Stack>
                        </Box>
                        <Box m={2}>
                            {createLanguageMutation.isError && messageError(createLanguageMutation.error)}
                        </Box>
                    </>
                }
            </Card>
        </>
    )
}