import React from "react";
import {FormInputText} from "../../../components/form/FormInputText";

import Grid from "@mui/material/Grid";
import {ISaveProductForm} from "../ISaveProductForm";
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import {FormControlLabel, FormGroup} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {Controller} from "react-hook-form";

export const Step2BddContainer: React.FC<ISaveProductForm> = (
    {control, watch, updateId}) => {

    const watchDemoDb = watch("database.demoDb", false);

    /*React.useEffect(() => {
        if ( watchDemoDb ) {
            control.unregister("database.hostname")
            control.unregister("database.port")
            control.unregister("database.username")
            control.unregister("database.password")
            control.unregister("database.name")
        } else {
            control.register("database.hostname")
            control.register("database.port")
            control.register("database.username")
            control.register("database.password")
            control.register("database.name")
        }
    }, [watchDemoDb]);*/
    return (
        <Grid container spacing={2} columnSpacing={{ xl: 3 }}>
            <Grid item md={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Controller
                                name={"database.demoDb"}
                                control={control}
                                render={({
                                     field: { onChange, value },
                                     fieldState: { error },
                                }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        }
                        label="Base de démo" />
                </FormGroup>
            </Grid>

            {/*<Grid item md={12}>
                <FormInputCheckbox onChange={handleChange} name="database.demoDb" control={control} label="Base démo"/>
            </Grid>*/}

            {(watchDemoDb) ? (
                <Grid item md={12}>
                    <Alert severity="warning">
                        <Typography display="block">Le choix d'une base de démo a été fait.</Typography>
                        <Typography display="block">Cette base est non persistée et sera vidée à chaque installation.</Typography>
                        <Typography display="block">Elle ne DOIT pas être utilisée en PRODUCTION</Typography>
                    </Alert>
                </Grid>
            ) : (
                <>
                    <Grid item md={12}>
                        <FormInputText name="database.hostname" control={control} label="hostname *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="database.port" control={control} label="port *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="database.username" control={control} label="username *"/>
                    </Grid>
                    <Grid item md={12}>
                        {(!updateId) ? ( <FormInputText name="database.password" control={control} label="password *" type="password"/>)
                            :(<FormInputText name="database.password" control={control} label="password (si inchangé, conserve celui déjà connu)" type="password"/>)}
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="database.name" control={control} label="name *"/>
                    </Grid>
                </>
            )}
        </Grid>
    )
}