import React, {useEffect} from "react";
import {FormLabel, Slider} from "@mui/material";
import {Controller} from "react-hook-form";
import {FormInputSliders} from "./FormInputSliders";


export const FormInputSliderMemory = ({
                                          minName,
                                          maxName,
                                          control,
                                          setValue,
                                          label,
                                      }: FormInputSliders) => {
    const [sliderValue, setSliderValue] = React.useState<number[]>([256, 512]);

    useEffect(() => {
        if (sliderValue) {setValue(minName, sliderValue[0]);setValue(maxName, sliderValue[1]);}
    }, [minName,maxName, setValue, sliderValue]);

    const handleChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number[]);
    };

    const marks = [
        {
            value: 256,
            label: '256Mi',
        },
        {
            value: 512,
            label: '512Mi',
        },
        {
            value: 768,
            label: '768Mi',
        },
        {
            value: 1024,
            label: '1024Mi',
        },
    ];

    function valuetext(value: number) {
        return `${value}Mi`;
    }

    return (
        <>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
                name={minName}
                control={control}
                render={({field, fieldState, formState}) => (
                    <Slider
                        value={sliderValue}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        size="small"
                        min={0}
                        max={1024}
                        step={64}
                        marks={marks}
                        getAriaValueText={valuetext}
                    />
                )}
            />
        </>
    );
};
