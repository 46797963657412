import * as React from 'react';
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {AssemblyRessource} from "../../../../types/index";

export const ResourcesComponent: React.FC<{
    resources?: AssemblyRessource
}> = (
    props: { resources?: AssemblyRessource }
) => {
    return (
        <TableContainer sx={{ mb: 2 }}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow key='tb1'>
                        <TableCell component="th" scope="row">requestsMemory</TableCell>
                        <TableCell component="th" scope="row">{props.resources?.requestsMemory}Mi</TableCell>
                    </TableRow>
                    <TableRow key='tb2'>
                        <TableCell component="th" scope="row">limitsMemory</TableCell>
                        <TableCell component="th" scope="row">{props.resources?.limitsMemory}Mi</TableCell>
                    </TableRow>
                    <TableRow key='tb3'>
                        <TableCell component="th" scope="row">requestsCpu</TableCell>
                        <TableCell component="th" scope="row">{props.resources?.requestsCpu}m</TableCell>
                    </TableRow>
                    <TableRow key='tb4'>
                    <TableCell component="th" scope="row">limitsCpu</TableCell>
                    <TableCell component="th" scope="row">{props.resources?.limitsCpu}m</TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}