import React from "react";
import {FormControl, FormLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import {FormInputProps} from "./FormInputProps";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {useQuery} from "@tanstack/react-query";
import {getKeycloakAvailableModes} from "../../services/ConfigurationProductService";
import Loading from "../Loading";

export const FormInputDropdownKeycloakMode: React.FC<FormInputProps> = ({
  name,
  control,
  label,
}) => {
  const auth: AuthContextProps = useAuth();

  const keycloakModesQuery = useQuery(
      ['keycloakAvailableModes'],
      () => getKeycloakAvailableModes(auth.user?.access_token)
  )

  if (keycloakModesQuery.isLoading) {
    return <Loading />
  }

  return (
    <FormControl size={"small"}>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select onChange={onChange} value={value}>
            {
              keycloakModesQuery.data.map((dataKeycloakMode: any) => {
                return (
                    <MenuItem key={dataKeycloakMode} value={dataKeycloakMode}>
                      {dataKeycloakMode}
                    </MenuItem>
                );
              })
            }
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};