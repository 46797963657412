import * as React from 'react'
import {Assembly, VersionLot} from "../../../../types/index";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {Accordion, AccordionDetails, AccordionSummary, Chip} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {PackageMvnListContainer} from "../../../Store/container/PackageMvnListContainer";
import {PackageNpmContainer} from "../../../Store/container/PackageNpmContainer";
import productService from "../../../../services/ProductService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Title from "../../../../components/Title";
import catalogueService from "../../../../services/CatalogueService";
import {UseQueryResult} from "@tanstack/react-query";

export const ModuleContainer: React.FC<{
    id: any
}> = (
    props: {
        id: any
    }) => {

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const auth: AuthContextProps = useAuth();

    const productById: UseQueryResult<Assembly, any> = productService.getProductById(auth, props.id);

    const lastVersionLotByModule: UseQueryResult<any> = catalogueService.useGetLastVersionLotByModule(auth); // ici

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const findLastVersionLot = (versionLot:VersionLot) : string | null => {
        let foundLastVersion: string | null = null;

        if(lastVersionLotByModule.data && versionLot.module?.id){
            const lastVersionLot: VersionLot | undefined = lastVersionLotByModule.data[versionLot.module.id];
            if(lastVersionLot?.id && lastVersionLot.id !== versionLot.id){
                foundLastVersion = lastVersionLot.numero;
            }
        }
        return foundLastVersion;
    }

    if (productById.data?.versionLots && productById.data.versionLots.length > 0) {
        productById.data.versionLots.sort((a: VersionLot, b: VersionLot) => a.module?.name && b.module?.name && a.module.name > b.module.name ? 1 : -1);
        return (
            <Card sx={{minHeight: 0}}>
                <CardContent>
                    <Title> Liste des packages</Title>
                {productById.data.versionLots.map((value: VersionLot) => {
                    return (
                        <Accordion expanded={expanded === `panel${value.id}`}
                                   onChange={handleChange(`panel${value.id}`)} key={`panel${value.id}bh`}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                              aria-controls={`panel${value.id}bh-content`}
                                              id={`panel${value.id}bh-header`}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="stretch"
                                      spacing={1}>
                                    <Grid item xs={2} md={2}>
                                        {value.numero} - {value.module?.name}
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        {value.description}
                                    </Grid>
                                    <Grid item xs={2} md={2} hidden={!findLastVersionLot(value)}>
                                        <Chip label={findLastVersionLot(value)} color="secondary" size="medium" />
                                    </Grid>

                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{backgroundColor: "gray"}}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="stretch"
                                      spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <PackageMvnListContainer versionLot={value} auth={auth}
                                                                 updateMode={false}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PackageNpmContainer versionLot={value} auth={auth} updateMode={false}/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
                </CardContent>
            </Card>
        );
    } else {
        return (<>Aucune données</>)
    }
}