import * as React from 'react';

// UI
import {
    Grid, Box, Card, CardContent, Container
} from "@mui/material";
import Title from "../../components/Title";

// COMPONENTS
import CurrenciesComponent from "./CurrenciesComponent";
import LanguagesComponent from "./LanguagesComponent";

export default function ConfigurationPage() {
    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{my: 4}}>
                        <Card sx={{minWidth: 275}}>
                            <CardContent>
                                <Title>Configuration</Title>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <LanguagesComponent />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <CurrenciesComponent />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}