import ListAltIcon from '@mui/icons-material/ListAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

import {IMenuItem} from '../types';
import {ROUTES} from './routes';

export const MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.main,
    literal: 'Dashboard',
    Icon: DashboardIcon,
  },
  {
    route: ROUTES.products,
    literal: 'Produits',
    Icon: ListAltIcon,
  },
  {
    route: ROUTES.store,
    literal: 'Catalogue',
    Icon: StorefrontIcon,
  },
  {
    route: ROUTES.configuration,
    literal: 'Configuration',
    Icon: BuildOutlinedIcon,
  },
  {
    route: ROUTES.trash,
    literal: 'Poubelle',
    Icon: DeleteOutlineIcon,
  },

];
