import dayjs from "dayjs";
import {api} from "../api";
import {IMetric} from "../types";
import {AuthContextProps} from "react-oidc-context";
import {QueryClient, useQuery} from "@tanstack/react-query";

class MetricsService {

    public metrics(auth: AuthContextProps,params: any){
        return api.get(`/kubernetes/metrics`, { params: params,
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public metricsNode(auth: AuthContextProps, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
        return useQuery({
            queryKey: [`productMetrics${props.pos}`, since, to],
            queryFn: () => this.fetchMetricsNode(auth, props, since, to),
            keepPreviousData: true,
        });
    }

    public metricsPod(auth: AuthContextProps, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
        return useQuery({
            queryKey: [`productMetrics${props.pos}`, since, to],
            queryFn: () => this.fetchMetricsPod(auth, props, since, to),
            keepPreviousData: true,
        });
    }

    public metricsQueryClientNode(auth: AuthContextProps, queryClient: QueryClient, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null){
        queryClient.prefetchQuery({
            queryKey: [`productMetrics${props.pos}`, since, to],
            queryFn: () => this.fetchMetricsNode(auth, props, since, to),
        })
    }

    private async fetchMetricsNode(auth: AuthContextProps, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
        const params = { since: since?.toDate(), to: to?.toDate() }
        const { data } = await api.get(`/kubernetes/metrics/node/${props.nodeName}`, { params: params ,
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        data.map((d: IMetric) => {
            d.time = dayjs(d.date).format('DD/MM/YY')
        })
        return data;
    }

    public metricsQueryClientPod(auth: AuthContextProps, queryClient: QueryClient, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null){
        queryClient.prefetchQuery({
            queryKey: [`productMetrics${props.pos}`, since, to],
            queryFn: () => this.fetchMetricsPod(auth, props, since, to),
        })
    }

    private async fetchMetricsPod(auth: AuthContextProps, props: any, since: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
        const params = { since: since?.toDate(), to: to?.toDate() }
        const { data } = await api.get(`/kubernetes/metrics/namespace/${props.namespace}/pod/${props.name}`, { params: params ,
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        data.map((d: IMetric) => {
            d.time = dayjs(d.date).format('DD/MM/YY')
        })
        return data;
    }
}

const metricsService: MetricsService = new MetricsService();

export default metricsService;