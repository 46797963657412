import {api} from "../api";
import {AxiosResponse} from "axios";
import {QueryClient, useMutation, useQuery, UseQueryResult} from "@tanstack/react-query";
import {AuthContextProps} from "react-oidc-context";
import {IFormInput} from "../types/index";
//import {AuthProviderProps} from "react-oidc-context";

export class KeycloakConfiguration {
    public authority: string | undefined;
    public clientId: string| undefined;
    public redirectUri: string| undefined;
    /*public onSigninCallback():void {
        window.history.replaceState(
            {},
            document.title,
        window.location.pathname
        )
    }*/
}

class ConfigurationService {

    public getKeycloakConfiguration(): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['configurationKeycloak'],
            queryFn: async (): Promise<any> => {
                const res : AxiosResponse<any, any> = await api.get<KeycloakConfiguration>(`/config-builder`, {});
                const resultat: any = {};
                if(res.status == 200){
                    resultat.authority = res.data.authority;
                    resultat.client_id = res.data.clientId;
                    resultat.redirect_uri = res.data.redirectUri;
                }
                return resultat;
            },
            keepPreviousData: true,
        })
    }
    /*
    public getSupportedCurrencies = async(access_token: string | undefined) => {
        const res = await api.get(
            `/configuration/supported-currencies`,
            {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }
        )
        return res.data
    }

    public useGetSupportedCurrencies(auth: AuthContextProps)
    {
        return useQuery({
            queryKey: ['supported-currencies'],
            queryFn: () => this.getSupportedCurrencies(auth.user?.access_token),
        });
    }

    public getSupportedLanguages(auth: AuthContextProps) {
        return useQuery({
            queryKey: ['supportedLanguages'],
            queryFn: async () => {
                const res = await api.get(`/configuration/supported-languages`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data
            },
        });
    }

    public usePostSupportedCurrency = (auth: AuthContextProps,queryClient: QueryClient) => {

        const {
            isLoading: isCreating,
            isSuccess: isSuccessCreating,
            isError: isErrorCreating,
            error: errorCreating,
            mutate: CreateCurrency
        } = useMutation(
            (data: string) => this.postSupportedCurrency(data, auth.user?.access_token),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['supported-currencies'] )

                    console.log("usePostSupportedCurrency >> OK")
                    console.log(queryClient)
                    console.log("usePostSupportedCurrency >> OK OK")
                }
            }
        )
        return {
            isCreating,
            isSuccessCreating,
            isErrorCreating,
            errorCreating,
            CreateCurrency
        }
    }

    public postSupportedCurrency = async (currency: string, access_token: string | undefined): Promise<any> =>
    {
        const res = await api.post(
            `/configuration/supported-currencies/${currency}`,currency,
            {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }}
        )
        return res.data
    }

    public useDeleteSupportedCurrency = (auth: AuthContextProps,queryClient: QueryClient) => {

        const {
            isLoading: isDeleting,
            isSuccess: isSuccessDeleting,
            isError: isErrorDeleting,
            error: errorDeleting,
            mutate: DeleteCurrency
        } = useMutation(
            (data: string) => this.deleteSupportedCurrency(data, auth.user?.access_token),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['supported-currencies'] })

                    console.log("useDeleteSupportedCurrency >> OK")
                }
            }
        )

        return {
            isDeleting,
            isSuccessDeleting,
            isErrorDeleting,
            errorDeleting,
            DeleteCurrency
        }
    }

    private deleteSupportedCurrency = async (currency: string, access_token: string | undefined): Promise<any> => {
        const res = await api.delete(
            `/configuration/supported-currencies/${currency}`,
            {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }}
        )
        return res.data
    }
    */
}

const configurationService: ConfigurationService = new ConfigurationService();
export default configurationService;