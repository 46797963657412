import React from "react";
import {VersionLot} from "../../../types/index";
import {ModuleSearchComponent} from "../../Store/components/ModuleSearchComponent";
import catalogueService from "../../../services/CatalogueService";
import Grid from "@mui/material/Grid";
import TitleCard from "../../../components/TitleCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {ISaveProductForm} from "../ISaveProductForm";
import {ProductVersionLotDetailsContainer} from "./ProductVersionLotDetailsContainer";
import {VersionLotListChooseContainer} from "./VersionLotListChooseContainer";
import productService from "../../../services/ProductService";

export const Step4ModuleContainer: React.FC<ISaveProductForm> = (
    {updateVersionLotChoose, updateId, versionLots, auth}) => {
    const [status, updateStatus] = React.useState<number>(0);

    const {isFetching: boolean, ...queryInfo} = catalogueService.useGetModules(auth);

    const {isFetching, ...queryInfoTemplate} = productService.useGetTemplateVersionUsedToBuild(auth);

    const [versionList, setVersionList] = React.useState(() => {
        const result: VersionLot[] = [];
        return result;
    });
    const [versionListChoose, setVersionListChoose] = React.useState(() => {
        let result: VersionLot[] = [];

        if (versionLots) {
            result = versionLots;
        }

        return result;
    });

    const refreshModeVersionList = (versionList: VersionLot[]) => {

        if (versionList.length > 1) {
            setVersionList([versionList[versionList.length - 1]]);
        } else {
            setVersionList(versionList);
        }
    };

    const refreshMode = (versionLot: VersionLot, addMode: boolean) => {
        let result: VersionLot[] = [];
        if (addMode) {
            result = versionListChoose;
            result.push(versionLot);
        } else {
            result = versionListChoose.filter(value => value.id != versionLot.id);
        }
        updateStatus((status) => status + 1);
        updateVersionLotChoose(result);
        setVersionListChoose(result);
    };
    console.log(queryInfoTemplate.data)
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <TitleCard>Version du template utilisé</TitleCard>
                    <CardContent>
                        {queryInfoTemplate.isSuccess &&
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} md={12}>
                                    {queryInfoTemplate.data}
                                </Grid>
                            </Grid>
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} md={8}>
                <Card>
                    <CardContent>
                        <TitleCard>Liste des modules disponibles</TitleCard>
                        {queryInfo.isSuccess &&
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <ModuleSearchComponent moduleDtos={queryInfo.data}
                                                           refreshModeVersionList={refreshModeVersionList}
                                                           auth={auth}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <VersionLotListChooseContainer versionList={versionList} auth={auth}
                                                                   versionListChoose={versionListChoose}
                                                                   refreshMode={refreshMode}/>
                                </Grid>
                            </Grid>

                        }
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={4} md={4}>
                <ProductVersionLotDetailsContainer versionListChoose={versionListChoose} refreshMode={refreshMode}/>
            </Grid>
        </Grid>
    )
}