import * as React from 'react';
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {AssemblyKeycloak} from "../../../../types/index";

export const KeycloakInfoComponent: React.FC<{
    keycloak: AssemblyKeycloak | undefined
}> = (
    props: { keycloak: AssemblyKeycloak | undefined }
) => {
    return (
        <TableContainer sx={{mb: 2}}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow key='tbk1'>
                        <TableCell component="th" scope="row">Mode</TableCell>
                        <TableCell component="th"
                                   scope="row">{props.keycloak?.keycloakMode}</TableCell>
                    </TableRow>
                    <TableRow key='tbk2'>
                        <TableCell component="th" scope="row">User admin</TableCell>
                        <TableCell component="th"
                                   scope="row">{props.keycloak?.adminUsername} / {props.keycloak?.adminPassword}</TableCell>
                    </TableRow>
                    {props.keycloak?.keycloakMode == "PROVIDER" &&
                        <>
                            <TableRow key='tbk10'>
                                <TableCell component="th" scope="row">redirectUri (à utiliser lors de la configuration
                                    du provider)</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.redirectUri}</TableCell>
                            </TableRow>
                            <TableRow key='tbk11'>
                                <TableCell component="th" scope="row">authorizationUrl</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.keycloakProviderConfig?.authorizationUrl}</TableCell>
                            </TableRow>
                            <TableRow key='tbk12'>
                                <TableCell component="th" scope="row">clientId</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.keycloakProviderConfig?.clientId}</TableCell>
                            </TableRow>
                        </>
                    }
                    {props.keycloak?.keycloakMode == "POSTGRES" &&
                        <>
                            <TableRow key='tbk10'>
                                <TableCell component="th" scope="row">Db host</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.keycloakDatabase?.hostname}:{props.keycloak?.keycloakDatabase?.port}</TableCell>
                            </TableRow>
                            <TableRow key='tbk12'>
                                <TableCell component="th" scope="row">Db Name</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.keycloakDatabase?.name}</TableCell>
                            </TableRow>
                            <TableRow key='tbk11'>
                                <TableCell component="th" scope="row">Db user</TableCell>
                                <TableCell component="th"
                                           scope="row">{props.keycloak?.keycloakDatabase?.username} / {props.keycloak?.keycloakDatabase?.password}</TableCell>
                            </TableRow>
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
        ;
}