import React, {useEffect} from "react";
import {FormLabel, Slider} from "@mui/material";
import {Controller} from "react-hook-form";
import {FormInputSliders} from "./FormInputSliders";


export const FormInputSliderCPU = ({
                                       minName,
                                       maxName,
                                       control,
                                       setValue,
                                       label,
                                   }: FormInputSliders) => {
    const [sliderValue, setSliderValue] = React.useState<number[]>([200, 500]);

    useEffect(() => {
        if (sliderValue) {
            setValue(minName, sliderValue[0]);
            setValue(maxName, sliderValue[1]);
        }
    }, [minName, maxName, setValue, sliderValue]);

    const handleChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number[]);
    };

    const marks = [
        {
            value: 500,
            label: '500m',
        },
        {
            value: 1000,
            label: '1000m',
        },
        {
            value: 1500,
            label: '1500m',
        },
        {
            value: 2000,
            label: '2000m',
        },
    ];

    function valuetext(value: number) {
        return `${value}m`;
    }

    return (
        <>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
                name={minName}
                control={control}
                render={({field, fieldState, formState}) => (
                    <Slider
                        value={sliderValue}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        size="small"
                        min={0}
                        max={2000}
                        step={10}
                        marks={marks}
                        getAriaValueText={valuetext}
                    />
                )}
            />
        </>
    );
};
