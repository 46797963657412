import {AxiosResponse} from "axios";
import {IFormInput, ModuleCatalogue, ModuleDto, PackageMvn, PackageNpm, VersionLot} from "../types";
import {AuthContextProps} from "react-oidc-context";
import {QueryClient, useMutation, useQuery, UseQueryResult} from "@tanstack/react-query";
import {api} from "../api";

class CatalogueService {

    public useGetLastVersionLotByModule(auth: AuthContextProps): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['lastversionlot'],
            queryFn: ({signal}) => this.getLastVersionLotByModule(signal, auth.user?.access_token),
        })
    }

    public useGetPackageNpm(auth: AuthContextProps): UseQueryResult<PackageNpm[], any> {
        return useQuery({
            queryKey: ['packagesNpm'],
            queryFn: ({signal}) => this.getPackageNpm(signal, auth.user?.access_token),
        })
    }

    public useGetPackageMvn(auth: AuthContextProps): UseQueryResult<PackageMvn[], any> {
        return useQuery({
            queryKey: ['packagesMvn'],
            queryFn: ({signal}) => this.getPackageMvn(signal, auth.user?.access_token),
        })
    }

    public useGetModules(auth: AuthContextProps): UseQueryResult<ModuleDto[], any> {
        return useQuery({
            queryKey: ['module'],
            queryFn: ({signal}) => this.getModules(signal, auth.user?.access_token),
        })
    }

    public usePackageNpm(auth: AuthContextProps): UseQueryResult<PackageNpm[], any> {
        return useQuery({
            queryKey: ['packageNpms'],
            queryFn: ({signal}) => this.getPackageNpm(signal, auth.user?.access_token),
        })
    }

    public usePackageMvn(auth: AuthContextProps): UseQueryResult<PackageMvn[], any> {
        return useQuery({
            queryKey: ['packageMvns'],
            queryFn: ({signal}) => this.getPackageMvn(signal, auth.user?.access_token),
        })
    }

    public postModule(auth: AuthContextProps, module: ModuleDto): Promise<AxiosResponse> {
        return api.post(`/module`,
            module, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public postVersionLot(auth: AuthContextProps, versionLot: VersionLot): Promise<AxiosResponse> {
        return api.post(`/versionlot`,
            versionLot, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public addPackageNpmDto(auth: AuthContextProps, idVersionLot:number|undefined, packageNpmDto: PackageNpm): Promise<AxiosResponse> {
        if(!idVersionLot){
            idVersionLot = 0;
        }
        packageNpmDto.id = null;
        return api.post(`/versionlot/package-npm/add/${idVersionLot}`,
            packageNpmDto, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public addPackageMvnDto(auth: AuthContextProps, idVersionLot:number|undefined, packageMvn: PackageMvn): Promise<AxiosResponse> {
        if(!idVersionLot){
            idVersionLot = 0;
        }
        packageMvn.id = null;
        return api.post(`/versionlot/package-mvn/add/${idVersionLot}`,
            packageMvn, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public deletePackageNpm(auth: AuthContextProps, id: number): Promise<AxiosResponse> {
        return api.delete(`/versionlot/package-npm/${id}`,{
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public deletePackageMvn(auth: AuthContextProps, id: number): Promise<AxiosResponse> {
        return api.delete(`/versionlot/package-mvn/${id}`,{
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public deleteVersionLot(auth: AuthContextProps, id: number): Promise<AxiosResponse> {
        return api.delete(`/versionlot/${id}`,{
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        });
    }

    public getLastModule(auth: AuthContextProps, props:any): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['moduleLastVersion', props.item.name],
            queryFn: async () => {
                const res = await api.get(`/catalog/module/lastversion`, {
                    params: { name: props.item.name },
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data
            },
        })
    };

    public usePostModuleCatalog = (auth: AuthContextProps,queryClient: QueryClient) => {
        const {
            isLoading: isCreating,
            isSuccess: isSuccessCreating,
            isError: isErrorCreating,
            error: errorCreating,
            mutate: CreateModule
        } = useMutation(
            (data: IFormInput) => this.postCatalogModule(data, auth.user?.access_token),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['supported-currencies'] )
                }
            }
        )

        return {
            isCreating,
            isSuccessCreating,
            isErrorCreating,
            errorCreating,
            CreateModule
        }
    }

    public useDeleteModuleCatalog = (auth: AuthContextProps,queryClient: QueryClient) => {
        const {
            isLoading: isDeleting,
            isSuccess: isSuccessDeleting,
            isError: isErrorDeleting,
            error: errorDeleting,
            mutate: DeleteModule
        } = useMutation(
            (data: string) => this.deleteCatalogModule(data, auth.user?.access_token),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['catalog'])
                }
            },
        )

        return {
            isDeleting,
            isSuccessDeleting,
            isErrorDeleting,
            errorDeleting,
            DeleteModule
        }
    }

    private deleteCatalogModule = async (data: string, access_token: string | undefined) => {
        const res = await api.delete(
            `catalog/module`, {
                params: {name: data},
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            },
            //data
        )
        return res.data
    }

    private postCatalogModule = async (data: IFormInput, access_token: string | undefined): Promise<any> => {
        const res = await api.post(
            'catalog/module',
            data,
            {
                params: {isCoreModule: false},
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }
        )
        console.log(res)
        return res.data
    }

    private getCatalog = async (signal: any, access_token: string | undefined): Promise<ModuleCatalogue> => {
        const res: AxiosResponse<any, any> = await api.get('catalog', {
            signal,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }

    private getModules = async (signal: any, access_token: string | undefined): Promise<ModuleDto[]> => {
        const res: AxiosResponse<any, any> = await api.get('module/search?name=', {
            signal,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }

    public getVersionlot = async (access_token: string | undefined, idModule: number): Promise<VersionLot[]> => {
        const res: AxiosResponse<any, any> = await api.get(`versionlot/${idModule}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }

    private getLastVersionLotByModule = async (signal: any, access_token: string | undefined): Promise<any> => {
        const res: AxiosResponse<any, any> = await api.get(`versionlot/last-version-by-module`, {
            signal,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }

    private getPackageNpm = async (signal: any,access_token: string | undefined): Promise<PackageNpm[]> => {
        const res: AxiosResponse<any, any> = await api.get(`packages/npm`, {
            signal,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }

    private getPackageMvn = async (signal: any,access_token: string | undefined): Promise<PackageMvn[]> => {
        const res: AxiosResponse<any, any> = await api.get(`packages/mvn`, {
            signal,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        return res.data
    }
}

const catalogueService:CatalogueService = new CatalogueService();
export default catalogueService;