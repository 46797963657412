import * as React from 'react';
import {PackageMvn, PackageNpm, VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import {PackageMvnListComponent} from "../components/PackageMvnListComponent";
import {PackageMvnDialogComponent} from "../components/PackageMvnDialogComponent";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

export const PackageMvnListContainer: React.FC<{ versionLot: VersionLot, auth: AuthContextProps, updateMode: boolean, disabled?: boolean }> = (
    props: { versionLot: VersionLot, auth: AuthContextProps, updateMode: boolean, disabled?: boolean }
) => {
    const [packageMvnDtos, setPackageMvnDtos] = React.useState<PackageMvn[]>(props.versionLot.packageMvnDtos);

    React.useEffect(() => {
        setPackageMvnDtos(props.versionLot.packageMvnDtos);
    }, [props.versionLot]);

    const updatePackageMvnDtos = (data: PackageNpm[]): void => {
        setPackageMvnDtos(data);
    }

    return (
        <Card>
            <CardContent>
                <PackageMvnDialogComponent versionLot={props.versionLot} packageMvnDtos={packageMvnDtos}
                                           updatePackageMvnDtos={updatePackageMvnDtos} auth={props.auth} hidden={!props.updateMode}/>
                <PackageMvnListComponent packageMvnDtos={packageMvnDtos} updatePackageMvnDtos={updatePackageMvnDtos} disabled={props.disabled}
                                         auth={props.auth} updateMode={props.updateMode}/>
            </CardContent>
        </Card>
    );
}