import React from "react";

import Grid from "@mui/material/Grid";
import {ISaveProductForm} from "../ISaveProductForm";
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import {FormControlLabel, FormGroup} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {Controller} from "react-hook-form";
import {FormInputText} from "../../../components/form/FormInputText";
import {FormInputDropdownPkceMethod} from "../../../components/form/FormInputDropdownPkceMethod";
import {FormInputDropdownClientAuthMethod} from "../../../components/form/FormInputDropdownClientAuthMethod";
import {FormInputDropdownSyncMode} from "../../../components/form/FormInputDropdownSyncMode";
import {FormInputDropdownKeycloakMode} from "../../../components/form/FormInputDropdownKeycloakMode";
import {Label} from "recharts";

export const Step3KeycloakContainer: React.FC<ISaveProductForm> = (
    {control, watch, updateId}) => {

    const watchMode = watch("keycloak.keycloakMode", "DEMO");
    const watchUseJwks = watch("keycloak.keycloakProviderConfig.useJwksUrl", false);
    const watchUsePkce = watch("keycloak.keycloakProviderConfig.usePkce", false);

    return (
        <Grid container spacing={2} columnSpacing={{xl: 3}}>
            <Grid item md={12}>
                <FormInputDropdownKeycloakMode name="keycloak.keycloakMode" control={control} label="keycloakMode"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="keycloak.adminUsername" control={control} label="adminUsername *"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="keycloak.adminPassword" control={control} label="adminPassword *"/>
            </Grid>

            {(watchMode == "DEMO") ? (
                <Grid item md={12}>
                    <Alert severity="warning">
                        <Typography display="block">Le choix d'un keycloak de démo a été fait.</Typography>
                        <Typography display="block">Ce keycloak est non persisté et sera vidé à chaque
                            installation.</Typography>
                        <Typography display="block">Il ne DOIT pas être utilisé en PRODUCTION</Typography>

                        <Typography display="block">L'utilisateur de test pour l'application est test/test</Typography>
                        <Typography display="block">L'utilisateur admin pour le keycloak est admin/admin</Typography>

                    </Alert>
                </Grid>
            ) : (<Grid></Grid>)}
            {(watchMode == "POSTGRES") ? (
                <>  <Grid item md={14}> <strong> Database </strong></Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakDatabase.hostname" control={control} label="hostname *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakDatabase.port" control={control} label="port *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakDatabase.username" control={control} label="username *"/>
                    </Grid>
                    <Grid item md={12}>
                        {(!updateId) ? ( <FormInputText name="keycloak.keycloakDatabase.password" control={control} label="password *" type="password"/>)
                            :(<FormInputText name="keycloak.keycloakDatabase.password" control={control} label="password (si inchangé, conserve celui déjà connu)" type="password"/>)}
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakDatabase.name" control={control} label="name *"/>
                    </Grid>
                </>
            ) : (<Grid></Grid>)}
            {(watchMode == "PROVIDER") ? (
                <>
                    <Grid item md={14}> <strong>Provider</strong> </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.authorizationUrl" control={control}
                                       label="authorizationUrl *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.tokenUrl" control={control}
                                       label="tokenUrl *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.clientId" control={control}
                                       label="clientId *"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.clientSecret" control={control}
                                       label="clientSecret" type="password"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.logoutUrl" control={control}
                                       label="logoutUrl"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.userInfoUrl" control={control}
                                       label="userInfoUrl"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputText name="keycloak.keycloakProviderConfig.issuer" control={control} label="issuer"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name={"keycloak.keycloakProviderConfig.validateSignature"}
                                        control={control}
                                        defaultValue={false}
                                        render={({
                                                     field: {onChange, value},
                                                     fieldState: {error},
                                                 }) => (
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                }
                                label="validateSignature"/>
                        </FormGroup>
                    </Grid>
                    <Grid item md={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name={"keycloak.keycloakProviderConfig.useJwksUrl"}
                                        control={control}
                                        defaultValue={false}
                                        render={({
                                                     field: {onChange, value},
                                                     fieldState: {error},
                                                 }) => (
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                }
                                label="useJwksUrl"/>
                        </FormGroup>
                    </Grid>
                    {(watchUseJwks) ? (
                            <Grid item md={12}>
                                <FormInputText name="keycloak.keycloakProviderConfig.jwksUrl" control={control}
                                               label="jwksUrl"/>
                            </Grid>)
                        : (<></>)}
                    <Grid item md={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name={"keycloak.keycloakProviderConfig.usePkce"}
                                        control={control}
                                        defaultValue={false}
                                        render={({
                                                     field: {onChange, value},
                                                     fieldState: {error},
                                                 }) => (
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                }
                                label="usePkce"/>
                        </FormGroup>
                    </Grid>
                    {(watchUsePkce) ? (
                            <Grid item md={12}>
                                <FormInputDropdownPkceMethod name="keycloak.keycloakProviderConfig.pkceMethod"
                                                             control={control} label="pkceMethod"/>
                            </Grid>)
                        : (<></>)}
                    <Grid item md={12}>
                        <FormInputDropdownClientAuthMethod name="keycloak.keycloakProviderConfig.clientAuthMethod"
                                                           control={control} label="clientAuthMethod"/>
                    </Grid>
                    <Grid item md={12}>
                        <FormInputDropdownSyncMode name="keycloak.keycloakProviderConfig.syncMode" control={control}
                                                   label="syncMode"/>
                    </Grid>

                </>
            ): (<Grid></Grid>)}

        </Grid>
    )
}