import * as React from 'react';
import {VersionLot} from "../../../types/index";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import TableCell from "@mui/material/TableCell";
import CardContent from "@mui/material/CardContent";
import TitleCard from "../../../components/TitleCard";
import Card from "@mui/material/Card";

export const ProductVersionLotDetailsContainer: React.FC<{
    versionListChoose: VersionLot[],
    refreshMode: any
}> = (
    props: { versionListChoose: VersionLot[], refreshMode: any}) => {

    const updateVersionLot = (versionLot: VersionLot, addMode: boolean) => {
        props.refreshMode(versionLot, addMode)
    }

    return (
        <Card>
            <CardContent>
                <TitleCard>Liste des modules sélectionnés</TitleCard>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Module</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell align="right">Version</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.versionListChoose.sort((a, b) => (a.module?.name && b.module?.name && a.module.name > b.module?.name) ? -1 : 1).map((value: VersionLot) => (
                            <TableRow key={value.id} sx={{'&:last-child td, &:last-child th': {border: 0}}} >
                                <TableCell component="th" scope="row">
                                    {value.module?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {value.numero}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="add" color="warning" onClick={event => {
                                        updateVersionLot(value, false);
                                    }}>
                                        <DeleteOutline/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}