import {api} from "../api";

export async function getSupportedCurrencies(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/supported-currencies`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}

export async function postSupportedCurrency(currency: string, access_token: string | undefined)
{
    const res = await api.post(
        `/configuration/supported-currencies/${currency}`,currency,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }}
    )
    return res.data
}

export async function deleteSupportedCurrency(currency: string, access_token: string | undefined)
{
    const res = await api.delete(
        `/configuration/supported-currencies/${currency}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }}
    )
    return res.data
}

export async function getSupportedLanguages(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/supported-languages`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}

export async function postSupportedLanguage(language: string, access_token: string | undefined)
{
    const res = await api.post(
        `/configuration/supported-languages/${language}`,language,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }}
    )
    return res.data
}

export async function deleteSupportedLanguage(language: string, access_token: string | undefined)
{
    const res = await api.delete(
        `/configuration/supported-languages/${language}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }}
    )
    return res.data
}

export async function getSupportedPkceMethods(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/keycloak/supported-pkce-methods`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}

export async function getKeycloakAvailableModes(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/keycloak/available-modes`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}

export async function getSupportedSyncModes(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/keycloak/supported-sync-modes`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}

export async function getSupportedClientAuthMethods(access_token: string | undefined) {

    const res = await api.get(
        `/configuration/keycloak/supported-client-auth-methods`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    )
    return res.data
}