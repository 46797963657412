import {AuthContextProps} from "react-oidc-context";
import {QueryClient, useMutation, useQuery, UseQueryResult} from "@tanstack/react-query";
import {api} from "../api";
import {Assembly, IFormInputProduct} from "../types";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {AxiosResponse} from "axios";

export interface ISavingProduct {
    isSaving: boolean;
    isSuccessSaving: boolean;
    isErrorSaving: boolean;
    errorSaving: any,
    SaveProduct: any
}

class ProductService {

    public productAssemblyPods(auth: AuthContextProps, props: any) {
        return useQuery({
            queryKey: ['productActivity'],
            queryFn: async () => {
                const res = await api.get(`/product-assembly/${props.id}/pods`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data
            },
        });
    }

    public listDeleteProductAssembly(auth: AuthContextProps, page: number) {
        return useQuery({
            queryKey: ['productsListDelete', page],
            queryFn: () => this.fetchProductsDelete(auth, page),
            keepPreviousData: true,
            staleTime: 5000,
            //refetchInterval: intervalMs,
        });
    }

    public listeDeleteProductAssemblyPage(auth: AuthContextProps, queryClient: QueryClient, page: number) {
        queryClient.prefetchQuery({
            queryKey: ['productsListDelete', page + 1],
            queryFn: () => this.fetchProductsDelete(auth, page + 1),
        })
    }

    private async fetchProductsDelete(auth: AuthContextProps, page = 0) {
        const {data} = await api.get('/product-assembly/deleted', {
            params: {page: page, per_page: 5},
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        return data;
    }

    public useGetTemplateVersionUsedToBuild(auth: AuthContextProps): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['templateVersion'],
            queryFn: ({signal}) => this.getTemplateVersionUsedToBuild(auth, signal),
        })
    }

    public useGetCount(auth: AuthContextProps): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['productsCount'],
            queryFn: ({signal}) => this.getCount(auth, signal),
        })
    }

    public undeployProductAssembly(auth: AuthContextProps, props: any) {
        api.delete(`/product-assembly/${props.id}/undeploy`, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response: AxiosResponse) => {
                if (response.status == 200) {
                    console.log("undeploy OK");
                } else {
                    alert("deploy KO");
                }
            });
    }

    public deployProductAssembly(auth: AuthContextProps, props: any) {
        api.post(`/product-assembly/${props.id}/deploy`,undefined,  {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response: AxiosResponse) => {
                if (response.status == 200) {
                    console.log("deploy OK");
                } else {
                    alert("deploy KO");
                }
            });
    }

    public deleteProductAssembly(auth: AuthContextProps, props: any, navigate: NavigateFunction) {
        api.delete(`/product-assembly/${props.id}`, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response: AxiosResponse) => {
                navigate("/");
            });
    }

    public deploymentStatus(auth: AuthContextProps, props: any, intervalMs: any) {
        return useQuery({
            queryKey: ['productStatus'],
            queryFn: async () => {
                const res = await api.get(`/product-assembly/${props.id}/deployment/status`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data;
            },
            refetchInterval: intervalMs,
        });
    }

    public updateListModule(auth: AuthContextProps, props: any, listModule: any) {
        api.put(`/product-assembly/${props.id}`, listModule, {
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response: AxiosResponse) => {
                console.log(response.status)
                if (response.status == 200) {
                    console.log("Put OK");
                    props.onUpdateChange(true);
                } else {
                    alert("Put KO");
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    public historyStatus(auth: AuthContextProps, props: any, page: number) {

        return useQuery({
            queryKey: ['productHistory', page],
            queryFn: () => this.fetchProductHistory(auth, props, page),
            keepPreviousData: true
        });
    }

    public productHistory(auth: AuthContextProps, props: any, queryClient: QueryClient, page: number): void {
        queryClient.prefetchQuery({
            queryKey: ['productHistory', page + 1],
            queryFn: () => this.fetchProductHistory(auth, props, page + 1),
        });
    }

    private async fetchProductHistory(auth: AuthContextProps, props: any, page: number = 0): Promise<any> {
        const {data} = await api.get(`/product-assembly/${props.id}/history-status`, {
            params: {page: page, per_page: 5},
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        return data;
    }

    public getProductByIdIngress(auth: AuthContextProps, props: any): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['productIngress'],
            queryFn: async () => {
                const res = await api.get(`/product-assembly/${props.id}/ingress`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data
            },
        });
    }

    public getProductById(auth: AuthContextProps, id: string | undefined): UseQueryResult<Assembly, any> {
        return useQuery({
            enabled: id !== undefined,
            queryKey: ['productDetail'],
            queryFn: async () => {
                const res: AxiosResponse<Assembly, any> = await api.get<Assembly>(`/product-assembly/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                })
                return res.data;
            },
        });
    }

    public usePostProduct = (auth: AuthContextProps, queryClient: QueryClient, navigate: NavigateFunction) => {
        const {
            isLoading: isSaving,
            isSuccess: isSuccessSaving,
            isError: isErrorSaving,
            error: errorSaving,
            mutate: SaveProduct
        } = useMutation(
            (data: IFormInputProduct) => this.postProduct(auth, data),
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(['productsList']) // à vérifier
                    navigate(`/product/${data}`);
                }
            }
        )

        return {
            isSaving,
            isSuccessSaving,
            isErrorSaving,
            errorSaving,
            SaveProduct
        }
    }

    public usePutProduct = (auth: AuthContextProps, updateId: any, queryClient: QueryClient, navigate: NavigateFunction) => {
        const {
            isLoading: isSaving,
            isSuccess: isSuccessSaving,
            isError: isErrorSaving,
            error: errorSaving,
            mutate: SaveProduct
        } = useMutation(
            (data: IFormInputProduct) => this.putProduct(auth, updateId, data),
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(['productsList']) // à vérifier
                    navigate(`/product/${data}`);
                }
            }
        )

        return {
            isSaving,
            isSuccessSaving,
            isErrorSaving,
            errorSaving,
            SaveProduct
        }
    }
    private postProduct = async (auth: AuthContextProps, data: IFormInputProduct) => {
        const res = await api.post(
            'product-assembly',
            data,
            {
                headers: {
                    'Authorization': `Bearer ${auth.user?.access_token}`
                }
            }
        )
        return res.data;
    }
    private putProduct = async (auth: AuthContextProps, updateId: any, data: IFormInputProduct) => {
        const res = await api.put(
            `product-assembly/${updateId}`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${auth.user?.access_token}`
                }
            }
        )
        return res.data;
    }


    private getTemplateVersionUsedToBuild = async (auth: AuthContextProps, signal: any): Promise<any> => {
        const res = await api.get('product-assembly/template-version-used-to-build', {
            signal,
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        return res.data;
    }

    private getCount = async (auth: AuthContextProps, signal: any): Promise<any> => {
        const res = await api.get('product-assembly/count', {
            signal,
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        return res.data;
    }

    public getAllProduct(auth: AuthContextProps, page: number): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['productsList', page],
            queryFn: () => this.fetchProducts(auth, page),
            keepPreviousData: true,
            staleTime: 5000,
            //refetchInterval: intervalMs,
        });
    }

    public productsList(auth: AuthContextProps, queryClient: QueryClient, page: number): void {
        queryClient.prefetchQuery({
            queryKey: ['productsList', page + 1],
            queryFn: () => this.fetchProducts(auth, page + 1),
        });
    }

    private async fetchProducts(auth: AuthContextProps, page = 0) {
        const {data} = await api.get('/product-assembly', {
            params: {page: page, per_page: 5},
            headers: {
                'Authorization': `Bearer ${auth.user?.access_token}`
            }
        })
        return data;
    }
}

const productService: ProductService = new ProductService();
export default productService;